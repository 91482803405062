import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'
import { Button } from '../atoms/buttons'
import { userDispatch } from '../store'

function CheckoutForm() {
    const stripe = useStripe()
    const elements = useElements()
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoading(true)

        const result = await stripe.confirmPayment({
            // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_FRONTEND_URL}/paymentSuccessful`, // this page should probably be a success page htat lasts for a few seconds and then direts you to the home page..
            },
        })

        if (result.error) {
             // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            console.log(result.error.message)
            
        } else {
            // naviage to return url
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            // clear client secret
            dispatch(userDispatch.setCreateSubscriptionSecret())
        }

        setIsLoading(false)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'fit-content' }}>
            <div style={{ width: '400px' }}>
                <form onSubmit={handleSubmit}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px'}}>
                    <PaymentElement />
                    {/* <button type='submit' disabled={!stripe}>Submit</button> */}
                    <Button type='submit' isLoading={isLoading} isDisabled={!stripe}>Submit</Button>
                    </div>
                    
                </form>
            </div>
        </div>
    )
}

export default CheckoutForm
