import React from 'react'
// import { Elements } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'
import { CheckIcon } from '@heroicons/react/20/solid'
import CheckoutForm from '../molecules/CheckoutForm'
import { priceTiers } from '../constants'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// const environment = process.env.NODE_ENV
// const stripePublishableKey = environment === 'production' ? process.env.REACT_APP_STRIPE_SECRET_PRODUCTION : process.env.REACT_APP_STRIPE_SECRET
// const stripePromise = loadStripe(stripePublishableKey)

// const stripePromise = loadStripe(stripePublishableKey).then((stripe) => {
//     if (!stripe) {
//       console.error('Stripe failed to load with the given publishable key.');
//     } else {
//       console.log('Stripe initialized successfully:');
//     }
//   });

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

export default function PayForSuscription() {
    const clientSecret = useSelector( // we probably shouldnt be storing this in redux for everyone to see?
        (state) => state.user.createdSubscriptionSecret?.clientSecret,
    )
    const selectedPriceTier = localStorage.getItem('@selectedPriceTier')

    // const options = {
    //     // passing the client secret obtained from the server
    //     clientSecret, 
    // }

    // console.log('environment: ', environment)
    // console.log('stripePublishableKey: ', stripePublishableKey)
    // console.log('clientSecret: ', clientSecret)
    // console.log('selectedPriceTier: ', selectedPriceTier)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const chosenTier = priceTiers.find((tier) => tier.priceTier === selectedPriceTier)


    return clientSecret ? (
        <div className='bg-white py-24 sm:py-32'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
                    <div
                        key={chosenTier.id}
                        className={classNames(
                            chosenTier.mostPopular
                                ? 'ring-2 ring-indigo-600'
                                : 'ring-1 ring-gray-200',
                            'rounded-3xl p-8 xl:p-10',
                            'w-fit; h-fit', // Add this class for a width of 450 pixels
                        )}
                    >
                        <div className='flex items-center justify-between gap-x-4'>
                            <h3
                                id={chosenTier.id}
                                className={classNames(
                                    chosenTier.mostPopular
                                        ? 'text-indigo-600'
                                        : 'text-gray-900',
                                    'text-lg font-semibold leading-8',
                                )}
                            >
                                {chosenTier.name}
                            </h3>
                            {chosenTier.mostPopular ? (
                                <p className='rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600'>
                                    Most popular
                                </p>
                            ) : null}
                        </div>
                        <p className='mt-4 text-sm leading-6 text-gray-600'>
                            {chosenTier.description}
                        </p>
                        <p className='mt-6 flex items-baseline gap-x-1'>
                            <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                {chosenTier.price[frequencies[0].value]}
                            </span>
                            <span className='text-sm font-semibold leading-6 text-gray-600'>
                                {frequencies[0].priceSuffix}
                            </span>
                        </p>
                        <ul className='mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10'>
                            {chosenTier.features.map((feature) => (
                                <li key={feature} className='flex gap-x-3'>
                                    <CheckIcon
                                        className='h-6 w-5 flex-none text-indigo-600'
                                        aria-hidden='true'
                                    />
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* <Elements stripe={stripePromise} options={options}> */}
                        <CheckoutForm />
                    {/* </Elements> */}
                </div>
            </div>
        </div>
    ) : (
        <>credentials missing for payment</>
    );
}
