/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useOneTimePayment } from '../apiCalls/payment/paymentMethods'
import { Button } from '../atoms/buttons'

export default function OneTimePaymentModal({
    successRedirectionRoute,
    cancellationRedirectionRoute,
    onBackdropClick,
    tokenCost,
    priceTier,
}) {
    const { createPaymentSession } = useOneTimePayment()
    const [quantity, setQuantity] = useState()

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            onBackdropClick() // Close modal on Escape key press
        }
    }

    return (
        <div className='fixed inset-0 z-50 flex items-center justify-center'>
            {/* Backdrop */}
            <div
                className='fixed inset-0 bg-gray-800 bg-opacity-50 z-40'
                onClick={onBackdropClick}
                onKeyDown={handleKeyDown}
                role='button'
                tabIndex='0'
                aria-label='Close modal'
            />
            {/* Modal */}
            {/* <div className='bg-white shadow sm:rounded-lg z-50'>
                <div className='px-4 py-5 sm:p-6'>
                    <h3 className='text-base font-semibold leading-6 text-gray-900'>
                        Buy more credits
                    </h3>
                    <div className='mt-2 max-w-xl text-sm text-gray-500'>
                        <p>{`Token cost is $${tokenCost} on ${priceTier} subscription tier`}</p>
                        <p>
                            Please enter the number of credits you would like to
                            purchase.
                        </p>
                    </div>
                    <form className='mt-5 sm:flex sm:items-center gap-5'>
                        <div className='flex items-center space-x-4 w-full sm:max-w-xs'>
                            <div className='w-full'>
                                <label htmlFor='quantity' className='sr-only'>
                                    Quantity
                                </label>
                                <input
                                    id='quantity'
                                    name='quantity'
                                    type='number'
                                    min={1}
                                    max={100}
                                    value={quantity}
                                    onChange={(e) =>
                                        setQuantity(e.target.value)
                                    }
                                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                />
                            </div>
                            <span className='text-gray-900'>
                                ${(quantity * tokenCost) || 0}
                            </span>
                        </div>

                        <Button
                            type='button'
                            size='sm'
                            // className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
                            onClick={() =>
                                createPaymentSession({
                                    successRedirectionRoute,
                                    cancellationRedirectionRoute,
                                    quantity
                                })
                            }
                            isDisabled={!quantity}
                        >
                            Buy
                        </Button>
                    </form>
                </div>
            </div> */}
            <div className='relative bg-white shadow sm:rounded-lg z-50'>
                <div className='absolute top-0 right-0 p-4'>
                    <button
                        onClick={onBackdropClick} // This function should handle closing the modal
                        className='text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                        type="button"
                    >
                        <span className='sr-only'>Close</span>
                        &#x2715; {/* This is the X symbol */}
                    </button>
                </div>
                <div className='px-4 py-5 sm:p-6'>
                    <h3 className='text-base font-semibold leading-6 text-gray-900'>
                        Buy more credits
                    </h3>
                    <div className='mt-2 max-w-xl text-sm text-gray-500'>
                        <p>{`Token cost is $${tokenCost} on ${priceTier} subscription tier`}</p>
                        <p>
                            Please choose the number of tokens you would like to
                            purchase.
                        </p>
                    </div>
                    <form className='mt-5 sm:flex sm:items-center gap-5'>
                        <div className='flex items-center space-x-4 w-full sm:max-w-xs'>
                      
                            <div className='w-full flex gap-[25px]'>
                                
                                
                                {/* <input
                                    id='quantity'
                                    name='quantity'
                                    type='number'
                                    min={1}
                                    max={100}
                                    value={quantity}
                                    onChange={(e) =>
                                        setQuantity(e.target.value)
                                    }
                                    className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                /> */}
                                <div className='flex flex-row gap-[5px] items-center'>
                                 <input
                                    id='ten'
                                    name='ten'
                                    type='radio'
                                    value={10}
                                    onChange={() =>
                                        setQuantity(10)
                                    }
                                    checked={quantity === 10}
                                    className='rounded-md shadow-sm'
                                />
                                
                                
                                <label htmlFor="ten">10</label>
                                </div>
                                <div className='flex flex-row gap-[5px] items-center'>
                                <input
                                    id='twenty'
                                    name='twenty'
                                    type='radio'
                                    value={20}
                                    onChange={() =>
                                        setQuantity(20)
                                    }
                                    checked={quantity === 20}
                                    className='rounded-md shadow-sm'
                                />
                                <label htmlFor="twenty">20</label>
                                </div>
                                <div className='flex flex-row gap-[5px] items-center'>
                                <input
                                    id='fifty'
                                    name='fifty'
                                    type='radio'
                                    value={50}
                                    onChange={() =>
                                        setQuantity(50)
                                    }
                                    checked={quantity === 50}
                                    className='rounded-md shadow-sm'
                                />
                                <label htmlFor="twenty">50</label>
                                </div>
                            </div>
                            <span className='text-gray-900'>
                                ${quantity * tokenCost || 0}
                            </span>
                        </div>

                        <Button
                            type='button'
                            size='sm'
                            // className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
                            onClick={() =>
                                createPaymentSession({
                                    successRedirectionRoute,
                                    cancellationRedirectionRoute,
                                    quantity: quantity / 10, // divide by 10 because 1 credit in stripe payasugo tier is actually $10 - which equals 10 in app tokens
                                })
                            }
                            isDisabled={!quantity}
                        >
                            Buy
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    )
}
