/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from "redux-persist"

const initialState = {
    name: undefined,
    subscription: undefined,
    createdSubscriptionSecret: undefined,
}

export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.name = action.payload.name

            if (action.payload.subscription) {
                // state.subscription.priceId = action.payload.subscription.priceId
                // state.subscription.isActive = action.payload.subscription.isActive
                state.subscription = {
                    priceTier: action.payload.subscription.priceTier,
                    isActive: action.payload.subscription.isActive,
                    subscriptionTokenBalance: action.payload.subscription?.subscriptionTokenBalance,
                    topUpTokenBalance: action.payload.subscription?.topUpTokenBalance,
                    topUpTokenCost: action.payload.subscription?.topUpTokenCost
                  
                }
            }
        },
        setCreateSubscriptionSecret: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.name = action.payload.name

            state.createdSubscriptionSecret = {
                subscriptionId: action.payload.subscriptionId,
                clientSecret: action.payload.clientSecret,
            }
        },
        clearSubscriptionSecret: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            
            state.createdSubscriptionSecret = null
        },
    },
})

user.extraReducers = (builder) => {
    builder.addCase(PURGE, () => initialState)
  }

// Action creators are generated for each case reducer function
export const { setUser, setCreateSubscriptionSecret, clearSubscriptionSecret } = user.actions

export const userReducer = user.reducer
