import React from 'react'

function PrivacyPage() {
    return (
        <div className='bg-white py-24 sm:py-32'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-4xl text-center'>
                    <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
                        Privacy Policy
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
1. Introduction`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
Content2Blog ("we," "our," "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your information.`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
2. Information Collection`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We collect information that you provide directly to us, such as your name, email address, and payment information when you use the Service.
`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`

3. Use of Information`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We use your information to:

Provide and improve the Service
Process transactions
Communicate with you about your account and the Service
Comply with legal obligations`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
4. Sharing of Information`}
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We do not share your personal information with third parties, except as necessary to provide the Service (e.g., payment processors) or as required by law.`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
5. Data Security`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We implement security measures to protect your information from unauthorized access, use, or disclosure. All payment transactions are processed through secure payment gateways.
`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
6. Cookies`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We use cookies to enhance your experience on the Site. You can control the use of cookies through your browser settings.
`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
7. Data Retention`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We retain your information for as long as necessary to provide the Service and as required by applicable laws.
`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
8. Your Rights`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
You have the right to access, update, or delete your personal information. To exercise these rights, contact us at tim@mail.content2blog.com.`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
9. Changes to Privacy Policy`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site.
`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
10. Contact Information`}</p>
<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
For any questions or concerns about this Privacy Policy, please contact us at:`}</p>

<p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
Email: tim@mail.content2blog.com
By using the Site, you agree to the collection and use of information in accordance with this Privacy Policy.`}
</p>
                    </p>
                </div>
            </div>
        </div>
    )
}
export default PrivacyPage
